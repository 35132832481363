.v2-topbar-section {
  	width:100%;
  	padding: 10px 0px !important;
    margin: 20px auto;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: white;
}

.v2-topbar-section label{
    font-weight: bold;
}

.v2-table-section {
    margin-top : 40px
}
.v2SubmitButton {
    width : 50%;
  margin-top:25px;
}