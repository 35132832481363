.payment-create-form {
    background-color: #FFF;
    /* height: 600px; */
    width: 31.250vw;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0px;
    border-top-left-radius: 0.521vw;
    border-top-right-radius: 0.521vw;
    padding: 0px;
    text-align: center;
}

.newPaymentLabel {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 0.833vw;
    color: #333;
    height: 1.042vw;
    width: 10.417vw;
    margin-top: 0.521vw;
    text-align: right;
    margin-right: 15px;
    float: left;
}

.react-select-payment {
    margin-top: 1.042vw;
    width: 90%
}

.payment-input {
    /* height: 40px; */
    width: 15.885vw;
    padding: 0.417vw;
    margin-top: 1.042vw;
}

.new-payment-submit {
    margin: 1.042vw 0px
}

.getDesiredDateDiv {
    margin-left: auto;
    margin-right: 15px;
    display: flex;
    justify-content: space-between;
    width: fit-content;
}

.desiredMonthBtn {
    border: 1px solid #17A2b8;
    outline: none;
    padding: 12px 20px;
    background-color: #ffffff;
    border-radius: 6px;
    margin-top: 35px;
    margin-left: 15px;
    cursor: pointer
}

.desiredMonthBtn:hover {
    color: #fff;
    background-color: #17A2b8;
}

.desiredMonthBtn:focus {
    background-color: #17A2b8;
    outline: none;
    color: #fff;
}

.btnDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
}

.csvDownloadBtn {
    margin-left: 10px;
}