.LoginBackground {
	height: 93.7vh;
	background: url("https://firebasestorage.googleapis.com/v0/b/zunpulse4development.appspot.com/o/images%2FZunWindow%2FZunPulse_Login.jpg?alt=media&token=b1a5dcfd-5f4d-431f-aa9e-439bb4cd86fd") no-repeat fixed center;
	background-size: cover;
}

.LoginComponent {
	width: 360px;
	margin: 0 auto;
	padding-top: 80px;
}

.authFormInside {
	padding:20px 10px 20px 10px;
}

.formBody {
	padding: 10px;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 1.5px 2.1px #827474;
}

.formHeading {
	text-align: center;
	padding-bottom: 5px;
	margin-bottom: 35px;
	box-shadow: 0px 1.5px #ccc;
}

.formHeader {
	font-size: 24px;
    font-weight: 400;
    line-height: 1.3333;
}

.formTop {
	margin-top: 20px
}

.formButton {
	padding-top: 10px;
	display: flex;
	flex: 1;
	flex-direction: row-reverse;
}

.btn.btn-primary {
	background-color: #3367d6;
}

@media only screen and (max-width: 786px) {
	.LoginBackground {
		height: 93vh;
	}
	.LoginComponent {
		width: 100%;
		margin: 0 auto;
		padding-top: 80px;
	}
	.loading {
		left: 57%;
	}
}