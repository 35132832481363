.buttons{
	width: 50%;
	display: flex;
	flex-direction: row-reverse;
}

.buttonTables{
    height:50%;
    padding: 10px 0; 
}

.btn.btn-success.salesAdmin {
	margin: 5px;
}

.cardCustom{
    margin:8px 0px;
}

.tableHead {
	display: flex;
	padding: 0px 18px;
}

.tableName{
    width: 50%;
    padding-top: 10px;
}