.mainBox {
	padding-top: 40px;
}

.btn.btn-primary.paymentViewButton {
	margin: 10px 0 0 16px;
}

.card-title .formTitle {
	display: flex;
	padding-top: 50px;
}

.card-title .formSubPart1 {
	width: 50%;
}

.card-title .formSubPart2 {
	width: 50%;
	display: flex;
	flex-direction: row-reverse;
}

.form-control.customInput {
	padding: 9px;
}


.btn-approval-main {
	display: flex;
}

.btn-approval-child {
	display: flex;
	width: 100%;
}

.filterPaymentMain {
	display: flex;
}

.filterPaymentChild {
	display: flex;
	width: 200px;
	margin: 0 10px;
}

.btn.btn-primary.paymentViewButton.btn-color {
	background-color: #ffffff;
	color: black;
}

.btn.btn-primary.paymentViewButton.btn-color.select {
	background-color: #29aba5;
	color: white;
}

.formButtons.heading-margin {
	margin-bottom: 50px;
}

.vendorAddForm {
	margin-top: 80px;
	border: 1px solid #aa8e8e;
	border-radius: 10px;
	padding: 24px;
}

.input-group-text.labelDefaultSize {
	width: 100px;
}

.ReactTable .rt-table .rt-tbody {
	overflow-x: hidden;
}