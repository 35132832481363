.stageTrackerMain {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fff;
}
.stageTrackerMain .heading {
    background-color: #63a69f;
    color: #fff;
    padding: 20px;
    text-align: center;
}
.stageTrackerMain .container-custom {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    flex-wrap: wrap;
}
.stageTrackerMain .stage {
    width: 22%;
    margin: 1%;
    padding: 12px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.stageTrackerMain .stage-new {
    width: 18%;
    margin: 1%;
    padding: 6px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.stageTrackerMain p {
    width: 100%;
    margin: 10px 0;
    font-size: 20px;
}
.stageTrackerMain .stage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 2px solid #63a69f;
}
.stageTrackerMain .stage-header h3 {
    margin: 0;
    color: #63a69f !important;
    text-decoration: none !important;
}
.stageTrackerMain .stage-header h3 a {
    color: #63a69f !important;
    text-decoration: none !important;
}
.stageTrackerMain .order-count {
    font-size: 15px;
    color: #777;
}
.stageTrackerMain .sub-stage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}
.stageTrackerMain .sub-stage-name {
    flex: 1;
    font-size: 14px;
    color: #333;
}

.stageTrackerMain .sub-stage-name a{
    flex: 1;
    font-size: 14px;
    color: #333;
    text-decoration: none;
}