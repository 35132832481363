.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.LoginBackground {
	height: 93.7vh;
	background: url("https://firebasestorage.googleapis.com/v0/b/zunpulse4development.appspot.com/o/images%2FZunWindow%2FZunPulse_Login.jpg?alt=media&token=b1a5dcfd-5f4d-431f-aa9e-439bb4cd86fd") no-repeat fixed center;
	background-size: cover;
}

.LoginComponent {
	width: 360px;
	margin: 0 auto;
	padding-top: 80px;
}

.authFormInside {
	padding:20px 10px 20px 10px;
}

.formBody {
	padding: 10px;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 1.5px 2.1px #827474;
}

.formHeading {
	text-align: center;
	padding-bottom: 5px;
	margin-bottom: 35px;
	box-shadow: 0px 1.5px #ccc;
}

.formHeader {
	font-size: 24px;
    font-weight: 400;
    line-height: 1.3333;
}

.formTop {
	margin-top: 20px
}

.formButton {
	padding-top: 10px;
	display: flex;
	flex: 1 1;
	flex-direction: row-reverse;
}

.btn.btn-primary {
	background-color: #3367d6;
}

@media only screen and (max-width: 786px) {
	.LoginBackground {
		height: 93vh;
	}
	.LoginComponent {
		width: 100%;
		margin: 0 auto;
		padding-top: 80px;
	}
	.loading {
		left: 57%;
	}
}
.animationload {
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 40px;
    width: 100%;
    z-index: 10000;
}
.loading {
    -webkit-animation: 1.5s linear 0s normal none infinite running loading;
            animation: 1.5s linear 0s normal none infinite running loading;
    background: #fed37f none repeat scroll 0 0;
    border-radius: 50px;
    height: 50px;
    left: 50%;
    margin-left: -50px;
    margin-top: -80px;
    position: absolute;
    top: 50%;
    width: 50px;
}
.loading::after {
    -webkit-animation: 1.5s linear 0s normal none infinite running loading_after;
            animation: 1.5s linear 0s normal none infinite running loading_after;
    border-color: #85d6de transparent;
    border-radius: 80px;
    border-style: solid;
    border-width: 10px;
    content: "";
    height: 80px;
    left: -15px;
    position: absolute;
    top: -15px;
    width: 80px;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        background: #85d6de none repeat scroll 0 0;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        background: #85d6de none repeat scroll 0 0;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
html, body {
	font-size: 12px;
}

.ReactTable {
	position: relative;
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable * {
	box-sizing: border-box
}

.ReactTable .rt-table {
	flex: auto 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	border-collapse: collapse;
	overflow: auto
}

.ReactTable .rt-thead {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	-webkit-user-select: none;
	user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
	background: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.ReactTable .rt-thead.-filters {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: #fff;
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 3px;
	font-weight: normal;
	outline: none
}

.ReactTable .rt-thead.-filters .rt-th {
	border-right: 1px solid rgba(0, 0, 0, 0.02)
}

.ReactTable .rt-thead.-header {
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15)
}

.ReactTable .rt-thead .rt-tr {
	text-align: center
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
	padding: 5px 5px;
	line-height: normal;
	position: relative;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
	box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
	box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6)
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
	box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6)
}

.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
	cursor: pointer
}

.ReactTable .rt-thead .rt-th:last-child, .ReactTable .rt-thead .rt-td:last-child {
	border-right: 0
}

.ReactTable .rt-thead .rt-resizable-header {
	overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
	overflow: hidden
}

.ReactTable .rt-thead .rt-resizable-header-content {
	overflow: hidden;
	text-overflow: ellipsis
}

.ReactTable .rt-thead .rt-header-pivot {
	border-right-color: #f7f7f7
}

.ReactTable .rt-thead .rt-header-pivot:after, .ReactTable .rt-thead .rt-header-pivot:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none
}

.ReactTable .rt-thead .rt-header-pivot:after {
	border-color: rgba(255, 255, 255, 0);
	border-left-color: #fff;
	border-width: 8px;
	margin-top: -8px
}

.ReactTable .rt-thead .rt-header-pivot:before {
	border-color: rgba(102, 102, 102, 0);
	border-left-color: #f7f7f7;
	border-width: 10px;
	margin-top: -10px
}

.ReactTable .rt-tbody {
	flex: 99999 1 auto;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
	border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
	border-bottom: 0
}

.ReactTable .rt-tbody .rt-td {
	border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
	border-right: 0
}

.ReactTable .rt-tbody .rt-expandable {
	cursor: pointer;
	text-overflow: clip
}

.ReactTable .rt-tr-group {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	align-items: stretch
}

.ReactTable .rt-tr {
	flex: 1 0 auto;
	display: inline-flex
}

.ReactTable .rt-th, .ReactTable .rt-td {
	flex: 1 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 7px 5px;
	overflow: hidden;
	transition: .3s ease;
	transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden, .ReactTable .rt-td.-hidden {
	width: 0 !important;
	min-width: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	opacity: 0 !important
}

.ReactTable .rt-expander {
	display: inline-block;
	position: relative;
	margin: 0;
	color: transparent;
	margin: 0 10px;
}

.ReactTable .rt-expander:after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(-90deg);
	transform: translate(-50%, -50%) rotate(-90deg);
	border-left: 5.04px solid transparent;
	border-right: 5.04px solid transparent;
	border-top: 7px solid rgba(0, 0, 0, 0.8);
	transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
	cursor: pointer
}

.ReactTable .rt-expander.-open:after {
	-webkit-transform: translate(-50%, -50%) rotate(0);
	transform: translate(-50%, -50%) rotate(0)
}

.ReactTable .rt-resizer {
	display: inline-block;
	position: absolute;
	width: 36px;
	top: 0;
	bottom: 0;
	right: -18px;
	cursor: col-resize;
	z-index: 10
}

.ReactTable .rt-tfoot {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
	border-right: 0
}

.ReactTable.-striped .rt-tr.-odd {
	background: rgba(0, 0, 0, 0.03)
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
	background: rgba(0, 0, 0, 0.05)
}

.ReactTable .-pagination {
	z-index: 1;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;
	padding: 3px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: #fff;
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 3px;
	font-weight: normal;
	outline: none
}

.ReactTable .-pagination .-btn {
	-webkit-appearance: none;
	appearance: none;
	display: block;
	width: 100%;
	height: 100%;
	border: 0;
	border-radius: 3px;
	padding: 6px;
	font-size: 1em;
	color: rgba(0, 0, 0, 0.6);
	background: rgba(0, 0, 0, 0.1);
	transition: all .1s ease;
	cursor: pointer;
	outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
	opacity: .5;
	cursor: default
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
	background: rgba(0, 0, 0, 0.3);
	color: #fff
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
	flex: 1 1;
	text-align: center
}

.ReactTable .-pagination .-center {
	flex: 1.5 1;
	text-align: center;
	margin-bottom: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around
}

.ReactTable .-pagination .-pageInfo {
	display: inline-block;
	margin: 3px 10px;
	white-space: nowrap
}

.ReactTable .-pagination .-pageJump {
	display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
	width: 70px;
	text-align: center
}

.ReactTable .-pagination .-pageSizeOptions {
	margin: 3px 10px
}

.ReactTable .rt-noData {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background: rgba(255, 255, 255, 0.8);
	transition: all .3s ease;
	z-index: 1;
	pointer-events: none;
	padding: 20px;
	color: rgba(0, 0, 0, 0.5)
}

.ReactTable .-loading {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.8);
	transition: all .3s ease;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
}

.ReactTable .-loading>div {
	position: absolute;
	display: block;
	text-align: center;
	width: 100%;
	top: 50%;
	left: 0;
	font-size: 15px;
	color: rgba(0, 0, 0, 0.6);
	-webkit-transform: translateY(-52%);
	transform: translateY(-52%);
	transition: all .3s cubic-bezier(.25, .46, .45, .94)
}

.ReactTable .-loading.-active {
	opacity: 1;
	z-index: 2;
	pointer-events: all;
}

.ReactTable .-loading.-active>div {
	-webkit-transform: translateY(50%);
	transform: translateY(50%)
}

.ReactTable .rt-resizing .rt-th, .ReactTable .rt-resizing .rt-td {
	transition: none !important;
	cursor: col-resize;
	-webkit-user-select: none;
	user-select: none
}

.row {
	/*padding: 15px;*/
}

.page-title {
	padding: 15px;
	margin: 0px auto;
	background-color: #cceffb;
}

.page_data {
	padding: 15px;
	margin: 2px auto;
}

.page_data__background {
	background-color: #e2f5fc;
}

.page_data__1 {
	/*border-left: 1px solid #D3D3D3;*/
}

.page_data__2 {
	border-left: 1px solid #D3D3D3;
	border-right: 1px solid #D3D3D3;
}

.page_data__3 {}

.col-md-2.col-6.filter_box {
	padding: 0px;
}

.filter_box_button {
	text-align: center;
}

.row_head {
	border-bottom: 1px solid #D3D3D3;
}

.row_data {
	justify-content: center;
	text-align: center;
}

.row_data__key {
	align-self: flex-start;
	padding: 10px;
	width: 100%
}

.row_data__key.col-4 {
	text-align: left;
}

.row_data__value {
	padding: 10px;
	align-self: center;
	width: 100%;
	word-wrap: break-word;
}

.styles_modal__gNwvD {
	width: 100%
}

.filter_stage {
	display: none;
}

.filter_selector__drop_down {
	display: flex;
}

.css-10nd86i {
	width: 100%;
}

.row_data__key.col-6 {
	padding-top: 0px;
	padding-bottom: 4px;
}

.row_data__value.col-6 {
	padding-top: 0px;
	padding-bottom: 4px;
}

.row_data__value.col-8 {
	padding-top: 0px;
	padding-bottom: 4px;
}

.row_data__key.col-4 {
	padding-top: 0px;
	padding-bottom: 4px;
}

.row_data__value.col-4 {
	padding-top: 0px;
	padding-bottom: 4px;
}

.row_data__key {
	padding-top: 0px;
	padding-bottom: 4px;
}

.filter_selector__drop_down1 {
	display: flex;
}

.filter_selector__drop_down2 {
	display: flex;
	margin-left: 29.7%;
}

.filter_row_3 {
	margin-top: 0%;
	display: flex;
}

.filter_selector {
	display: flex;
}

.filter_count {
	width: 100%;
	display: flex;
}

.filter_selector__drop_down_label1 {
	margin-top: 2%;
	font-weight: 800;
}

.filter_count__value.filter_count__value1 {
	border: 2px solid #6f6f6f;
	width: 100%;
	text-align: center;
}

.filter_count__size {
	display: flex;
	width: 9.1%;
	margin-left: 9%;
}

.filter_count__value.filter_count__value2 {
	border: 2px solid #6f6f6f;
	width: 100%;
	text-align: center;
}

.filter_count__roofs {
	display: flex;
	width: 9.1%;
	margin-left: 9%;
}

.drop_down1 {
	margin-left: 3%;
	width: 100%;
	padding: 4%;
}

.filter_selector__stage_label3 {
	margin-top: 0.4%;
	font-weight: 800;
}

.filter_count__label.filter_count__label1 {
	font-weight: 800;
}

.filter_count__label.filter_count__label2 {
	font-weight: 800;
}

.filter_selector__bar_1 {
	display: flex;
	margin-top: 1%;
}

.filter_stage {
	margin-left: 0.5%;
	display: flex;
	width: 98%;
	margin-top: 5px;
}

.filter_stage__button {
	width: 8%;
	border: 1px solid black;
	margin: 0 auto;
	height: 95%;
	text-align: center;
}

.open_arrow {
	padding-top: 5px;
	padding-right: 13px;
}

.deals_table_head {
	width: 100%;
	text-align: end;
	color: rgba(2, 105, 6, 0.84);
}

.fa-cog:hover {
	color: rgba(36, 197, 42, 0.84);
	cursor: pointer;
}

.fa-plus-circle.circle_create {
	font-size: 20px;
	padding-top: 2px;
	color: rgba(2, 105, 6, 0.84);
}

.fa-plus-circle.circle_create:hover {
	font-size: 22px;
	color: rgba(36, 197, 42, 0.84);
}

.stageStatus {
	width: 24px;
	border-radius: 29px;
	height: 24px;
	margin: 0px auto;
	text-align: center;
	color: white;
}

.redStage {
	background-color: #e83333;
}

.yellowStage {
	background-color: #e68e1e;
}

.greenStage {
	background-color: rgb(26, 175, 26);
}

.fas.fa-plus-circle.addIncentive {
	font-size: 22px;
	margin-top: 16px;
	padding: 0 10px;
	color: green;
}

.fas.fa-plus-circle.addIncentive:hover {
	cursor: pointer;
	font-size: 24px;
	margin-top: 16px;
	padding: 0 10px;
	color: #20ce20;
}

.navMargin {
	margin-top: 40px;
}

.font-icon {
	font-size: 22px;
	margin-top: 4px;
	padding: 0 10px;
	color: green;
}

.font-icon:hover {
	cursor: pointer;
	font-size: 24px;
	color: #20ce20;
}

/*LOADER CSS*/

.animationload {
	background-color: #fff;
	height: 100%;
	left: 0;
	position: fixed;
	top: 40px;
	width: 100%;
	z-index: 10000;
}

.loading {
	-webkit-animation: 1.5s linear 0s normal none infinite running loading;
	        animation: 1.5s linear 0s normal none infinite running loading;
	background: #fed37f none repeat scroll 0 0;
	border-radius: 50px;
	height: 50px;
	left: 50%;
	margin-left: -50px;
	margin-top: -80px;
	position: absolute;
	top: 50%;
	width: 50px;
}

.loading::after {
	-webkit-animation: 1.5s linear 0s normal none infinite running loading_after;
	        animation: 1.5s linear 0s normal none infinite running loading_after;
	border-color: #85d6de transparent;
	border-radius: 80px;
	border-style: solid;
	border-width: 10px;
	content: "";
	height: 80px;
	left: -15px;
	position: absolute;
	top: -15px;
	width: 80px;
}

@-webkit-keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	50% {
		background: #85d6de none repeat scroll 0 0;
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	50% {
		background: #85d6de none repeat scroll 0 0;
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.properties-flex {
	display: flex;
	flex-wrap: wrap;
}

.flex-child {
	width: 70px;
}

.flex-child-1 {
	display: flex;
	flex-wrap: wrap;
	width: 50%;
}

.flex-child-2 {
	display: flex;
	flex-wrap: wrap-reverse;
	flex-direction: row-reverse;
	width: 50%;
}

@media only screen and (max-width: 720px) {
	.flex-child-1 {
		width: 100%;
	}
	.flex-child-2 {
		width: 100%;
	}
}

.css-15k3avv {
	z-index: 1000
}

.primeCust {
	margin-left: 30%;
	font-size: 15px;
	color: #072507;
	text-shadow: 0px 1px #072507;
}

.markZunprimeButton {
	/* margin: 0 5px; */
	float: right;
}

.mainBox {
	padding-top: 40px;
}

.btn.btn-primary.paymentViewButton {
	margin: 10px 0 0 16px;
}

.card-title .formTitle {
	display: flex;
	padding-top: 50px;
}

.card-title .formSubPart1 {
	width: 50%;
}

.card-title .formSubPart2 {
	width: 50%;
	display: flex;
	flex-direction: row-reverse;
}

.form-control.customInput {
	padding: 9px;
}


.btn-approval-main {
	display: flex;
}

.btn-approval-child {
	display: flex;
	width: 100%;
}

.filterPaymentMain {
	display: flex;
}

.filterPaymentChild {
	display: flex;
	width: 200px;
	margin: 0 10px;
}

.btn.btn-primary.paymentViewButton.btn-color {
	background-color: #ffffff;
	color: black;
}

.btn.btn-primary.paymentViewButton.btn-color.select {
	background-color: #29aba5;
	color: white;
}

.formButtons.heading-margin {
	margin-bottom: 50px;
}

.vendorAddForm {
	margin-top: 80px;
	border: 1px solid #aa8e8e;
	border-radius: 10px;
	padding: 24px;
}

.input-group-text.labelDefaultSize {
	width: 100px;
}

.ReactTable .rt-table .rt-tbody {
	overflow-x: hidden;
}
.buttons{
	width: 50%;
	display: flex;
	flex-direction: row-reverse;
}

.buttonTables{
    height:50%;
    padding: 10px 0; 
}

.btn.btn-success.salesAdmin {
	margin: 5px;
}

.cardCustom{
    margin:8px 0px;
}

.tableHead {
	display: flex;
	padding: 0px 18px;
}

.tableName{
    width: 50%;
    padding-top: 10px;
}
.payment-create-form {
    background-color: #FFF;
    /* height: 600px; */
    width: 31.250vw;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0px;
    border-top-left-radius: 0.521vw;
    border-top-right-radius: 0.521vw;
    padding: 0px;
    text-align: center;
}

.newPaymentLabel {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 0.833vw;
    color: #333;
    height: 1.042vw;
    width: 10.417vw;
    margin-top: 0.521vw;
    text-align: right;
    margin-right: 15px;
    float: left;
}

.react-select-payment {
    margin-top: 1.042vw;
    width: 90%
}

.payment-input {
    /* height: 40px; */
    width: 15.885vw;
    padding: 0.417vw;
    margin-top: 1.042vw;
}

.new-payment-submit {
    margin: 1.042vw 0px
}

.getDesiredDateDiv {
    margin-left: auto;
    margin-right: 15px;
    display: flex;
    justify-content: space-between;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.desiredMonthBtn {
    border: 1px solid #17A2b8;
    outline: none;
    padding: 12px 20px;
    background-color: #ffffff;
    border-radius: 6px;
    margin-top: 35px;
    margin-left: 15px;
    cursor: pointer
}

.desiredMonthBtn:hover {
    color: #fff;
    background-color: #17A2b8;
}

.desiredMonthBtn:focus {
    background-color: #17A2b8;
    outline: none;
    color: #fff;
}

.btnDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.csvDownloadBtn {
    margin-left: 10px;
}
.v2-topbar-section {
  	width:100%;
  	padding: 10px 0px !important;
    margin: 20px auto;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: white;
}

.v2-topbar-section label{
    font-weight: bold;
}

.v2-table-section {
    margin-top : 40px
}
.v2SubmitButton {
    width : 50%;
  margin-top:25px;
}
.stageTrackerMain {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fff;
}
.stageTrackerMain .heading {
    background-color: #63a69f;
    color: #fff;
    padding: 20px;
    text-align: center;
}
.stageTrackerMain .container-custom {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    flex-wrap: wrap;
}
.stageTrackerMain .stage {
    width: 22%;
    margin: 1%;
    padding: 12px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.stageTrackerMain .stage-new {
    width: 18%;
    margin: 1%;
    padding: 6px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.stageTrackerMain p {
    width: 100%;
    margin: 10px 0;
    font-size: 20px;
}
.stageTrackerMain .stage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 2px solid #63a69f;
}
.stageTrackerMain .stage-header h3 {
    margin: 0;
    color: #63a69f !important;
    text-decoration: none !important;
}
.stageTrackerMain .stage-header h3 a {
    color: #63a69f !important;
    text-decoration: none !important;
}
.stageTrackerMain .order-count {
    font-size: 15px;
    color: #777;
}
.stageTrackerMain .sub-stage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}
.stageTrackerMain .sub-stage-name {
    flex: 1 1;
    font-size: 14px;
    color: #333;
}

.stageTrackerMain .sub-stage-name a{
    flex: 1 1;
    font-size: 14px;
    color: #333;
    text-decoration: none;
}
